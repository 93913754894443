import PoweredBy from "../../core/components/PoweredBy";
import { useConfig } from "../../core/contexts/ConfigContext";

const questionsInformation = [
  {
    id: 1,
    title: "Equal Opportunity Employer/Self identification",
    subtitle: "An Equal Opportunity Employer",
    description: `
All  qualified  applicants  will  receive  consideration  for  employment  without  regard  to  race, color,  religion,  age,  sex,  national  origin,  disability,  pregnancy,  sexual  orientation,  gender identity/gender expression, citizenship status, military or veteran status, genetic information or any other status or condition that is protected by applicable law.

You are invited to provide the following information voluntarily. This information will remain CONFIDENTIAL and will be used only for purposes allowed by law. Refusal to provide such information will not subject you to any adverse treatment nor will it become part of your personnel  record  should  you  become  employed  by  Dawn  Foods.  Furthermore,  please understand that this information will not be shared with any manager or supervisor who is involved  in  the  decision-making  process  as  to  whether  or  not  you  will  be  considered  for employment by the Company. Thank you for your cooperation.`,
  },
  {
    id: 2,
    title: "Voluntary Self-Identification of Disability",
    subtitle: `Form CC-305
OMB Control Number 1250-0005
Expires 04/30/2026    
`,
    description: [
      {
        id: 1,
        title: "Why are you being asked to complete this form?",
        description: `We  are  a  federal  contractor  or  subcontractor.  The  law  requires  us  to  provide  equal employment opportunity to qualified people with disabilities. We have a goal of having at least  7%  of  our  workers  as  people  with  disabilities.  The  law  says  we  must  measure  our progress towards this goal. To do this, we must ask applicants and employees if they have a disability  or  have  ever  had  one.  People  can  become  disabled,  so  we  need  to  ask  this question at least every five years.

Completing this form is voluntary, and we hope that you will choose to do so. Your answer is confidential. No one who makes hiring decisions will see it. Your decision to complete the form and your answer will not harm you in any way. If you want to learn more about the law or  this  form,  visit  the  U.S.  Department  of  Labor’s  Office  of  Federal  Contract  Compliance Programs (OFCCP) website at www.dol.gov/ofccp            
`,
      },
      {
        id: 2,
        title: "What qualifies as a disability?",
        description:
          "A disability is a condition that substantially limits one or more of your “major life activities.” If you have or have ever had such a condition, you are a person with a disability. Disabilities include, but are not limited to:",
        list: [
          [
            "Alcohol  or  other  substance  use disorder  (not  currently  using  drugs illegally)",
            "Autoimmune  disorder,  for  example, lupus,  fibromyalgia,  rheumatoid arthritis, or HIV/AIDS",
            "Blind or low vision",
            "Cancer (past or present)",
            "Cardiovascular or heart disease",
            "Celiac disease",
            "Cerebral palsy",
            "Deaf or serious difficulty hearing",
            "Diabetes",
            "Disfigurement,  for  example, disfigurement  caused  by  burns, wounds,  accidents, or congenital disorders",
            "Epilepsy or other seizure disorder",
            "Gastrointestinal  disorders,  for example,  Crohn's  Disease,  irritable bowel syndrome",
          ],
          [
            "Intellectual  or  developmental disability",
            "Mental  health  conditions,  for example,  depression,  bipolar disorder,  anxiety  disorder, schizophrenia, PTSD",
            "Missing  limbs  or  partially  missing limbs",
            "Mobility  impairment,  benefiting  from the  use  of  a  wheelchair,  scooter, walker,  leg  brace(s)  and/or  other supports",
            "Nervous  system  condition,  for example,  migraine  headaches, Parkinson’s  disease,  multiple sclerosis (MS)",
            "Neurodivergence,  for  example, attention-deficit/hyperactivity disorder  (ADHD),  autism  spectrum disorder,  dyslexia,  dyspraxia,  other learning disabilities",
            "Partial  or  complete  paralysis  (any cause)",
            "Pulmonary  or  respiratory  conditions, for  example,  tuberculosis,  asthma, emphysema",
            "Short stature (dwarfism)",
            "Epilepsy or other seizure disorder",
            "Traumatic brain injury",
          ],
        ],
        footer:
          "PUBLIC  BURDEN  STATEMENT:  According  to  the  Paperwork  Reduction  Act  of  1995  no persons  are  required  to  respond  to  a  collection  of  information  unless  such  collection displays a valid OMB control number. This survey should take about 5 minutes to complete",
      },
    ],
  },
  {
    id: 3,
    title: "Voluntary Self-Identification of Veteran Status",
    description: [
      {
        id: 1,
        description: `This  employer  is  a  Government  contractor  subject  to  the  Vietnam  Era  Veterans' Readjustment Assistance Act of 1974, as amended by the Jobs for Veterans Act of 2002,38 U.S.C. 4212 (VEVRAA), which requires Government contractors to take affirmative action to employ and advance in employment: (1) disabled veterans; (2) recently separated veterans; (3) active duty wartime or campaign badge veterans; and (4) Armed Forces service medal veterans. These classifications are defined as follows:`,
        list: [
          {
            item: `A "disabled veteran" is one of the following:`,
            sublist: [
              "A veteran of the U.S. military, ground, naval or air service who is entitled to compensation  (or  who  but  for  the  receipt  of  military  retired  pay  would  be entitled  to  compensation)  under  laws  administered  by  the  Secretary  of Veterans Affairs; or",
              "A  person  who  was  discharged  or  released  from  active  duty  because  of  a service-connected disability.",
            ],
          },
          `A  "recently  separated  veteran"  means  any  veteran  during  the  three-year  period beginning on the date of such veteran's discharge or release from active duty in the U.S. military, ground, naval, or air service.`,
          `An "active duty wartime or campaign badge veteran" means a veteran who served on active  duty  in  the  U.S.  military,  ground,  naval  or  air  service  during  a  war,  or  in  a campaign or expedition for which a campaign badge has been authorized under the laws administered by the Department of Defense.`,
          `An  "Armed  forces  service  medal  veteran"  means  a  veteran  who,  while  serving  on active duty in the U.S. military, ground, naval or air service, participated in a United States  military  operation  for  which  an  Armed  Forces  service  medal  was  awarded pursuant to Executive Order 12985.`,
        ],
        footer: `Protected  veterans  may  have  additional  rights  under  USERRA—the  Uniformed  Services Employment  and  Reemployment  Rights  Act.  In  particular,  if  you  were  absent  from employment in order to perform service in the uniformed service, you may be entitled to be reemployed  by  your  employer  in  the  position  you  would  have  obtained  with  reasonable certainty  if  not  for  the  absence  due  to  service.  For  more  information,  call  the  U.S. Department  of  Labor's  Veterans  Employment  and Training  Service  (VETS),  toll-free,  at  1-866-4-USA-DOL.

Submission of this information is voluntary and refusal to provide it will not subject you to any  adverse  treatment.  The  information  provided  will  be  used  only  in  ways  that  are  not inconsistent  with  the  Vietnam  Era  Veterans'  Readjustment  Assistance  Act  of  1974,  as amended.
        
The  information  you  submit  will  be  kept  confidential,  except  that  (i)  supervisors  and managers may be informed regarding restrictions on the work or duties of disabled veterans, and  regarding  necessary  accommodations;  (ii)  first  aid  and  safety  personnel  may  be informed, when and to the extent appropriate, if you have a condition that might require emergency treatment; and (iii) Government officials engaged in enforcing laws administered by the Office of Federal Contract Compliance Programs, or enforcing the Americans with Disabilities Act, may be informed.`,
      },
    ],
  },
  {
    id: 4,
    title: "Acknowledgement and Consent",
    description: `I understand that employment by the Company is contingent upon the Company obtaining references  and  verifying  my  legal  work  status.  I  understand  that,  if  offered  employment, such  offer  may  be  conditioned  upon  satisfactory  completion  of  a  physical  examination, background checks and drug screen. I understand that failure to meet all pre-employment contingencies will result in any conditional offer of employment being withdrawn.

I understand  that  any  false  or  misleading  statements  or  omissions  on  the  application, attached resume or any subsequent background forms will be sufficient cause for rejection of this application or immediate termination if hired. I also understand that the use of this application form does not indicate that there are any positions available and does not in any way obligate the Company to offer me employment, or if I am employed, to continue my employment for any specific period of time. Furthermore, I understand that just as I am free to resign at any time, the Company reserves the right to terminate my employment at any time, with or without cause, and without prior notice. I understand that no representative of the Company has the authority to make any assurances to the contrary.

All  qualified  applicants  will  receive  consideration  for  employment  without  regard  to  race, color,  religion,  age,  sex,  national  origin,  disability,  pregnancy,  sexual  orientation,  gender identity/gender expression, citizenship status, military or veteran status, genetic information or any other status or condition that is protected by applicable law.`,
  },
];

const QuestionTitle = ({ title }) => (
  <h1 className="text-desktopNeutralBlack text-center text-3xl mb-4 whitespace-pre-wrap">
    {title}
  </h1>
);

const QuestionSubTitle = ({ subtitle }) => (
  <h2 className="text-desktopNeutralDark text-center text-2xl mb-4 whitespace-pre-wrap">
    {subtitle}
  </h2>
);

const QuestionDescription = ({ description }) => {
  const renderList = (list) => {
    // Check if the list is an array of arrays (multi-column support)
    if (Array.isArray(list[0])) {
      return (
        <div className="flex flex-row gap-x-4">
          {list.map((sublist, columnIndex) => (
            <ul
              key={columnIndex}
              className="flex-1 list-disc ml-6 text-desktopNeutralBlack mb-2"
            >
              {sublist.map((item, index) => (
                <li key={index} className="break-inside-avoid">
                  {typeof item === "string" ? (
                    item
                  ) : (
                    <>
                      <p className="mb-2">{item.item}</p>
                      {item.sublist && renderList(item.sublist)}
                    </>
                  )}
                </li>
              ))}
            </ul>
          ))}
        </div>
      );
    }

    return (
      <ul className={"list-disc ml-6 text-desktopNeutralBlack mb-2"}>
        {list.map((item, index) => (
          <li key={index}>
            {typeof item === "string" ? (
              item
            ) : (
              <>
                <p className="mb-2">{item.item}</p>
                {item.sublist && renderList(item.sublist)}
              </>
            )}
          </li>
        ))}
      </ul>
    );
  };

  if (Array.isArray(description)) {
    return description.map((section) => (
      <div key={section.id} className="my-4">
        {section.title && (
          <h3 className="text-desktopNeutralBlack text-lg font-semibold mb-2">
            {section.title}
          </h3>
        )}
        <p className="text-desktopNeutralBlack mb-2 whitespace-pre-wrap">
          {section.description}
        </p>
        {section.list && renderList(section.list)}
        {section.footer && (
          <p className="text-desktopNeutralVeryDark text-xs mt-4 whitespace-pre-wrap">
            {section.footer}
          </p>
        )}
      </div>
    ));
  }

  return (
    <p className="my-4 text-desktopNeutralBlack whitespace-pre-wrap">
      {description}
    </p>
  );
};

const EEPLegalWidget = () => {
  const { config } = useConfig();
  const logoSrc =
    config?.company_logo_url &&
    new URL(config.company_logo_url, import.meta.env.APPLY_PUBLIC_STORAGE_URL)
      .href;

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center p-4">
      <div className="flex flex-col items-center text-center w-full mb-4">
        {logoSrc ? (
          <img
            src={logoSrc}
            alt="Company avatar"
            className={"h-20 object-contain mb-4"}
          />
        ) : (
          <div
            className={"text-desktopNeutralBlack font-semiboald text-3xl mb-4"}
          >
            <span>{config?.company_name}</span>
          </div>
        )}
      </div>
      <div className="max-w-6xl">
        {questionsInformation.map((question) => (
          <div key={question.id} className="mb-12">
            <QuestionTitle title={question.title} />
            <QuestionSubTitle subtitle={question.subtitle} />
            <QuestionDescription description={question.description} />
          </div>
        ))}
      </div>
      <div className="max-w-6xl w-full mt-auto flex flex-col items-center">
        <hr className="border-t border-gray-300 my-4 w-full" />
        <PoweredBy />
      </div>
    </div>
  );
};

export default EEPLegalWidget;
